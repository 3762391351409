/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';

$custom-primary: (
  50: #e0f2f1,
  // your shades of the custom color
  100: #b2dfdb,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #0b2559,
  // your primary hex color
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$my-custom-theme: mat-light-theme(
  mat-palette($custom-primary),
  // using your custom primary color
  mat-palette($mat-pink) // accent palette, you can customize this similarly
);
@include angular-material-theme($my-custom-theme);

$yellow-color: #ffc107;
$blue: #0b2559;
html,
body {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: rgba(242, 245, 250, 1);
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
$space-values: (0, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50) !default;

// margin and padding shorthands
$space-prefixes: (
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes {
    @each $value in $space-values {
      .#{$attr-short}-#{$value} {
        #{$attr-long}: #{$value}#{'px'};
      }
    }
  }
}

$sizes: 10px, 20px, 30px, 40px, 50px, 100px, 150px, 300px;

@each $size in $sizes {
  .h-#{$size} {
    height: $size !important;
  }
}

@include make-spaces();
.yellow {
  color: $yellow-color;
}

.pointer {
  cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sticky {
    position: relative !important;
  }
  .hidden-xs {
    display: none;
  }
  .pt-125 {
    padding-top: 20px !important;
  }
}
.border-yellow {
  border-color: $yellow-color !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  background-color: rgba(48, 70, 114, 0.0980392156862745);
}
.title {
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 15px;
}
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  background-color: white;
}
.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.justify-flex {
  display: flex;
  justify-content: space-between;
}
.left-flex {
  display: flex;
  justify-content: left;
}
@media only screen and (max-width: 1200px) {
  .flex-container,
  .left-flex .justify-flex {
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sticky {
    position: relative !important;
  }
  .pt-125 {
    padding-top: 20px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sticky {
    position: relative !important;
  }
  .pt-125 {
    padding-top: 20px !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sticky {
    position: fixed !important;
  }
  .pt-125 {
    padding-top: 60px !important;
    //background-color: white !important;
  }
}
.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-container.start {
  justify-content: flex-start;
}

.flex-container.end {
  justify-content: flex-end;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #fff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: black;
}

.snack-bar-filtre-campagne {
  --mdc-snackbar-container-color: #f89406;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
}

// Timepicker config
.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}
.timepicker-overlay {
  z-index: 9999 !important;
}
.timepicker__header {
  background-color: #0b2559 !important;
}
.wating {
  color: #ffffff;
  background-color: #ffc107;
  border: 1px solid #ffffff;
  text-align: center;
}
.erreur {
  color: #ffffff;
  background-color: #d00019;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 80px;
  text-align: center;
}
.ended {
  color: #ffffff;
  background-color: #2bdf07;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 80px;
  text-align: center;
}
.in-proress {
  color: #ffffff;
  background-color: #ffa500;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 80px;
  text-align: center;
}
.badge-gray {
  color: #ffffff;
  background-color: #8f958e;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 185px;
}
.badge-green-directeur {
  color: #ffffff;
  background-color: #2bdf07;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 185px;
}
.badge-green-dga {
  color: #ffffff;
  background-color: #50833c;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 185px;
}
.badge-red {
  color: #ffffff;
  background-color: #d00019;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 185px;
}

.badge-green-actif {
  color: #ffffff;
  background-color: #50833c;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 80px;
}

.badge-gray-inactif {
  color: #ffffff;
  background-color: #8f958e;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 80px;
}

.badge-red-statut {
  color: #ffffff;
  background-color: #d00019;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 60px;
}
.badge-green-statut {
  color: #ffffff;
  background-color: #50833c;
  border: 1px solid #ffffff;
  border-radius: 3px;
  width: 60px;
}
